;
// Ana Menü
const navigation = new Navigation(document.getElementById("navigation"));

// Main Slider
if (document.querySelector('.hero-slider')) {
  const swiper = new Swiper(".hero-slider .swiper", {
    // navigation: {
    //   nextEl: ".hero-slider .slider-ok-sag",
    //   prevEl: ".hero-slider .slider-ok-sol",
    // },
    pagination: {
      el: ".swiper-pagination",
    },
    grabCursor: false,
    effect: "creative",
    effect: "fade",
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
  });
}

// Footer Galeri Slider
if (document.querySelector('.footer-galeri-slider')) {
  const swiper = new Swiper(".footer-galeri-slider .swiper", {
    slidesPerView: 4,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 1500,
    },
    breakpoints: {
      640: {
        slidesPerView: 5,
        spaceBetween: 0,
      },
      992: {
        slidesPerView: 6,
        spaceBetween: 0,
      },
      1200: {
        slidesPerView: 7,
        spaceBetween: 0,
      },
      1400: {
        slidesPerView: 8,
        spaceBetween: 0,
      },
    },
  });
}

// Eğitim Haber Slider
if (document.querySelector('.egitim-haber-slider')) {
  const swiper = new Swiper(".egitim-haber-slider .swiper", {
    slidesPerView: 1,
    spaceBetween: 20,
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination",
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
  });
}

document.getElementById('year').innerText = new Date().getFullYear()

// let sayi1 = new CountUp('sayi1', 6);
// let sayi2 = new CountUp('sayi2', 20);
// let sayi3 = new CountUp('sayi3', 100);

// sayi1.start()
// sayi2.start()
// sayi3.start()
